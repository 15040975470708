@use "sass:color";

@import "../../vendor/styles/bootstrap.scss";
@import "../../vendor/styles/appwork.scss";
@import "../../vendor/styles/_appwork/include";
@import "../../vendor/styles/_theme/common";
@import "../../vendor/styles/_theme/libs";
@import "../../vendor/styles/_theme/uikit";
@import "../../vendor/styles/colors.scss";
@import "../../vendor/styles/uikit.scss";
@import "../../vendor/styles/theme-react-date-range.scss";
@import "./virtual-window.scss";

$overdueColour: #fb5227 !default;
$unpaidColour: #f9a53c !default;
$acceptedColour: #448dde !default;
$quotedColour: #30c3b8 !default;
$draftColour: #bdbdbd !default;
$paidColour: #4faf5e !default;
$refundColour: #eb0006 !default;
$voidColour: #7f6cc6 !default;
$incompleteColour: #448dde !default;
$timelineOutline: $draftColour !default;

$carnation: #f16364 !default;
$tanHide: #f58559 !default;
$seaBuckthorn: #f9a43e !default;
$sunflower: #e4c62e !default;
$fern: #67bf74 !default;
$fountainBlue: #59a2be !default;
$curiousBlue: #2093cd !default;
$tapestry: #ad62a7 !default;
$trendyPink: #805781 !default;

:root {
  --overdueColour: #{$overdueColour};
  --unpaidColour: #{$unpaidColour};
  --acceptedColour: #{$acceptedColour};
  --quotedColour: #{$quotedColour};
  --draftColour: #{$draftColour};
  --paidColour: #{$paidColour};
  --refundColour: #{$refundColour};
  --voidColour: #{$voidColour};
  --incompleteColour: #{$incompleteColour};
  --timelineOutline: #{$timelineOutline};

  --carnation: #{$carnation};
  --tanHide: #{$tanHide};
  --seaBuckthorn: #{$seaBuckthorn};
  --sunflower: #{$sunflower};
  --fern: #{$fern};
  --fountainBlue: #{$fountainBlue};
  --curiousBlue: #{$curiousBlue};
  --tapestry: #{$tapestry};
  --trendyPink: #{$trendyPink};
}

$state-colors: (
  overdue: $overdueColour,
  unpaid: $unpaidColour,
  accepted: $acceptedColour,
  quoted: $quotedColour,
  draft: $draftColour,
  paid: $paidColour,
  refund: $refundColour,
  void: $voidColour,
  incomplete: $incompleteColour,
  timelineOutline: $timelineOutline,
);

$invoice-tag-colors: (
  carnation: $carnation,
  tanHide: $tanHide,
  seaBuckthorn: $seaBuckthorn,
  sunflower: $sunflower,
  fern: $fern,
  fountainBlue: $fountainBlue,
  curiousBlue: $curiousBlue,
  tapestry: $tapestry,
  trendyPink: $trendyPink,
);

.bg-carnation {
  background-color: #f16364 !important;
}
.bg-tanHide {
  background-color: #f58559 !important;
}
.bg-seaBuckthorn {
  background-color: #f9a43e !important;
}
.bg-sunflower {
  background-color: #e4c62e !important;
}
.bg-fern {
  background-color: #67bf74 !important;
}
.bg-fountainBlue {
  background-color: #59a2be !important;
}
.bg-curiousBlue {
  background-color: #2093cd !important;
}
.bg-tapestry {
  background-color: #ad62a7 !important;
}
.bg-trendyPink {
  background-color: #805781 !important;
}

.text-carnation {
  color: #f16364 !important;
}
.text-tanHide {
  color: #f58559 !important;
}
.text-seaBuckthorn {
  color: #f9a43e !important;
}
.text-sunflower {
  color: #e4c62e !important;
}
.text-fern {
  color: #67bf74 !important;
}
.text-fountainBlue {
  color: #59a2be !important;
}
.text-curiousBlue {
  color: #2093cd !important;
}
.text-tapestry {
  color: #ad62a7 !important;
}
.text-trendyPink {
  color: #805781 !important;
}


@each $tag, $color in $invoice-tag-colors {
  .bg-#{$tag} {
    background-color: #{$color};
  }
  .text-#{$tag} {
    color: #{$color};
  }
}

.gap-2 {
  gap: 0.5rem;
}

.invoice-tag-color {
  height: 3rem;
  aspect-ratio: 1/1;
  border: 1px solid white;
  border-radius: 50%;
  outline: 0;

  &.active,
  &:focus,
  &:active {
    outline: 2px solid $primary-color !important;
    outline-offset: 1px !important;
  }
}

span.state-badge {
  text-transform: capitalize;
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  text-align: center;
}

@mixin state-badge($option, $color, $triangleSize: 1.25rem) {
  .filter-options {
    .#{$option} {
      color: $color;
    }
  }
  .state-badge {
    &.text-#{$option} {
      color: $color;
      background-color: white;
    }
  }
  .state-badge,
  .state-banner {
    &.bg-#{$option} {
      color: white;
      background-color: $color;
    }
  }
  .zig-zag {
    width: 100%;
    height: 6px;
    background-image: url("../../../public/assets/img/zig-zag.svg");
    background-repeat: repeat-x;
  }
}

@each $state, $color in $state-colors {
  @include state-badge($state, $color);
}

.state-badge {
  &.partially-refunded {
    white-space: nowrap;
    color: $refundColour;
    border: 1px solid $refundColour;
  }
}

.stat-value {
  font-weight: 700;
  font-size: 1.6em;
}

.sidenav-inner {
  padding-bottom: 60px;
}

.layout-content {
  position: relative;
}

.summary-sales-count {
  .card-header {
    color: $white;
    background-color: map-get($theme-colors, "info");
  }
  border: 1px solid map-get($theme-colors, "info") !important;
  color: map-get($theme-colors, "info");
}
.summary-gross-sales {
  .card-header {
    color: $white;
    background-color: map-get($theme-colors, "success");
  }
  border: 1px solid map-get($theme-colors, "success") !important;
  color: map-get($theme-colors, "success");
}
.summary-refunds {
  .card-header {
    color: $white;
    background-color: map-get($theme-colors, "danger");
  }
  border: 1px solid map-get($theme-colors, "danger") !important;
  color: map-get($theme-colors, "danger");
}
.summary-net-sales {
  .card-header {
    color: $white;
    background-color: map-get($theme-colors, "primary");
  }
  border: 1px solid map-get($theme-colors, "primary") !important;
  color: map-get($theme-colors, "primary");
}
.summary-net-sales-includes {
  .card-header {
    color: $black;
    background-color: map-get($theme-colors, "light");
  }
  border: 1px solid map-get($theme-colors, "light") !important;
  color: $black;
}
.summary-payment-breakdown {
  .card-header {
    color: $white;
    background-color: map-get($theme-colors, "dark");
  }
  border: 1px solid map-get($theme-colors, "dark") !important;
  color: $black;
}

.cover {
  position: absolute;
  padding: 24px 32px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
  overflow: auto;
  z-index: 5;
}

.pre {
  white-space: pre;
}

.code-container {
  width: 282px;
}
.code-character {
  border: 2px solid $primary-color;
  border-radius: 6px;
}
.code-character-inactive {
  background-color: #fff;
}

:root {
  --rs-picker-value: #{$primary-color} !important;
  --rs-bg-active: #{$primary-color} !important;
  --rs-input-focus-border: #{$primary-color} !important;
  --rs-listbox-option-hover-text: #{$primary-color} !important;
  --rs-calendar-range-bg: #{color.scale($primary-color, $lightness: 90%)} !important;
  --rs-listbox-option-hover-bg: #{color.scale($primary-color, $lightness: 90%)} !important;

  --rs-state-focus-shadow: 0 0 0 3px #{color.scale($primary-color, $lightness: 90%)} !important;

  --rs-btn-link-text: #{$primary-color} !important;
  --rs-btn-link-hover-text: #{darken($primary-color, 5%)} !important;
  --rs-btn-link-active-text: #{darken($primary-color, 10%)} !important;

  --rs-btn-primary-bg: #{$primary-color} !important;
  --rs-btn-primary-hover-bg: #{darken($primary-color, 5%)} !important;
  --rs-btn-primary-active-bg: #{darken($primary-color, 10%)} !important;
  // TODO: override all RS values or replace RS
}

.rs-stack {
  display: flex;
}
.rs-picker-daterange-predefined {
  border-right: 1px solid #e5e5ea;
  border-right: 1px solid var(--rs-border-primary);
  height: 366px;
  padding: 4px 0;
}
.rs-picker-toolbar::before,
.rs-picker-toolbar::after {
  content: none !important;
  display: none !important;
}

body {
  background: $body-bg;
}

.bg-body {
  background: $body-bg !important;
}

@include appwork-common-theme($primary-color);
@include appwork-libs-theme($primary-color);
@include appwork-uikit-theme($primary-color);

// Navbar

@include appwork-navbar-variant(".bg-navbar-theme", #fff, $color: $text-muted, $active-color: $body-color);

.layout-navbar {
  box-shadow: 0 10px 30px 0 $border-color;
}

// Sidenav

@include appwork-sidenav-variant(".bg-sidenav-theme", $sidenav-bg-color, $color: #fff, $menu-bg: $sidenav-bg-color);

.bg-sidenav-theme {
  background: linear-gradient(to top, $sidebar-gradient-start, $sidebar-gradient-end);

  .sidenav-inner nav ul li > .sidenav-item > .sidenav-link .sidenav-icon,
  .sidenav-inner nav ul li > .sidenav-item > .sidenav-link:hover .sidenav-icon,
  .sidenav-inner nav ul li > .sidenav-item > .sidenav-link:focus .sidenav-icon {
    color: rgba(255, 255, 255, 0.7);
  }

  .sidenav-item:not(.active) > .sidenav-link > div,
  .sidenav-item > .sidenav-link.sidenav-toggle > div {
    transition: all 0.2s;
  }
  .sidenav-item:not(.active) > .sidenav-link:hover > div,
  .sidenav-item > .sidenav-link.sidenav-toggle:hover > div {
    opacity: 0.8;
  }

  .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background: rgba(0, 0, 0, 0.1) !important;
  }
}

.bg-sidenav-theme .sidenav-vertical,
.bg-sidenav-theme.sidenav-vertical {
  background-attachment: fixed;
  background-size: 100% 100vh;

  .sidenav-item:not(.active) > .sidenav-link:hover > div,
  .sidenav-item > .sidenav-link.sidenav-toggle:hover > div {
    opacity: 0.8;
    transform: translateX(8px);
  }
}

[dir="rtl"] .bg-sidenav-theme .sidenav-vertical,
[dir="rtl"] .bg-sidenav-theme.sidenav-vertical {
  .sidenav-item:not(.active) > .sidenav-link:hover > div,
  .sidenav-item > .sidenav-link.sidenav-toggle:hover > div {
    transform: translateX(-8px);
  }
}

// Footer

@include appwork-footer-variant(".bg-footer-theme", $body-bg, $color: $black, $active-color: $body-color);

.layout-footer {
  box-shadow: 0 -1px 0 $border-color;
}

// Custom styling

hr {
  border-color: $border-color;
}

.card,
.nav-tabs-top > .tab-content,
.nav-tabs-right > .tab-content,
.nav-tabs-bottom > .tab-content,
.nav-tabs-left > .tab-content {
  border-color: transparent;
  box-shadow: 0 10px 30px 0 rgba($black, 0.04);
}
.aw-wizard-boxed-steps ul.steps-indicator,
.form-wizard-boxed-steps .wizard-nav {
  border-color: transparent !important;
  box-shadow: 0 10px 30px 0 rgba($black, 0.04) !important;
}

.nav-tabs-top,
.nav-tabs-right,
.nav-tabs-bottom,
.nav-tabs-left {
  > .nav-tabs:not(.tabs-alt) .nav-link:not(.active),
  > div > .nav-tabs:not(.tabs-alt) .nav-link:not(.active) {
    border-color: transparent !important;
  }

  > .nav .nav-link.active,
  > div > .nav .nav-link.active {
    border-color: $nav-tabs-link-active-bg !important;
  }
}

.nav-tabs-top > .nav-tabs:not(.tabs-alt),
.nav-tabs-top > div > .nav-tabs:not(.tabs-alt),
.nav-tabs-bottom > .nav-tabs:not(.tabs-alt),
.nav-tabs-bottom > div > .nav-tabs:not(.tabs-alt) {
  padding-right: $border-width;
  padding-left: $border-width;
}
.nav-tabs-right > .nav-tabs:not(.tabs-alt),
.nav-tabs-right > div > .nav-tabs:not(.tabs-alt),
.nav-tabs-left > .nav-tabs:not(.tabs-alt),
.nav-tabs-left > div > .nav-tabs:not(.tabs-alt) {
  padding-top: $border-width;
  padding-bottom: $border-width;
}

.pre-wrap {
  white-space: pre-wrap;
}

$base-size: 8px;
.adjust-callout,
.adjust-margin {
  width: calc(100% + ($base-size * 2));
  margin-left: -$base-size;
  margin-top: -($base-size / 2);
  display: grid;
  > button,
  > div {
    white-space: pre-wrap;
    padding: ($base-size / 2) $base-size;
  }
  > button {
    text-align: left;
    border: 0;
    line-height: inherit;
    color: inherit;
  }
}
.adjust-callout {
  > div {
    border-left: 2px solid $text-lighter;
    padding-left: $base-size - 2px;
  }
}

// invoice edit modal's vertical btn groups
.lineItem-btn-group.btn-group-vertical {
  > button {
    text-align: left;
    border-radius: 0;
    border: 1px solid $text-lightest;
  }
}

.tag-remove-btn {
  &:hover {
    background-color: red;
  }
}
